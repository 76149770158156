import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import { paragraph } from "../data/efpa-associate-data"
import List from "../components/List"
import parse from "react-html-parser"
import PdfButton from "../components/PdfButton"
import { listWithChildren, listJubilados } from "../data/retiree-benefits-data"
import TitlePage from "../components/TitlePage"

export default function EfpaAssociate({ location }) {
  const path = [
    { label: "Inicio", to: "/" },
    {
      label: "Certificaciones - ¿Cómo hacerme asociado de EFPA España?",
    },
  ]
  return (
    <Layout>
      <TitlePage
        title="¿Cómo hacerme asociado de EFPA España?"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <div className="EfpaAssociate">
          <Breadcrumb path={path} />
          <div className="EfpaAssociate-content">
            <TitleBlock
              title="¿Cómo hacerme asociado de EFPA España?"
              icon="fas fa-edit"
            />
            <div className="EfpaAssociate-text">
              {parse(paragraph)}
              <img
                src="/imgs/IMAGEN-CUOTAS-COMPLETAS-WEB.jpg"
                style={{ maxWidth: "100%" }}
                alt="CUOTAS-COMPLETAS"
              />
            </div>
          </div>
          <div className="EfpaAssociate-content">
            <TitleBlock
              title="Código Ético y Declaración de Honorabilidad"
              icon="far fa-edit"
            />
            <p className="EthicalCode-text">
              <a href="/docs/codigo_etico.pdf" target="_blank">
                El Código Ético de la asociación
              </a>
              , establece los estándares de conducta personal y profesional que
              han de cumplir los miembros de la asociación. Al igual que en el
              resto de las delegaciones nacionales de EFPA, en EFPA España se
              establece un Comité Deontológico que se encarga de evaluar
              aquellos casos de incumplimiento del Código Ético y otros casos en
              que se cuestione la conducta de los miembros de la Asociación.
            </p>
            <p className="EthicalCode-text">
              La Asociación vela por que todos sus miembros cumplan los
              estándares mencionados en el Código Ético, para así mejorar la
              opinión que tienen los clientes, el sector de servicios
              financieros y el público en general.
            </p>
            <p className="EthicalCode-text">
              A su vez, los Miembros empleados pueden estar sujetos a normas y
              requisitos específicos como consecuencia del contrato laboral.
            </p>
            <PdfButton
              isExternal
              className="mb-2"
              text="Firmar Código Ético"
              link="/docs/Firma_codigo_etico.pdf"
            />
            <PdfButton
              isExternal
              className="mb-2"
              text="Declaración de Honorabilidad"
              link="/docs/Declaracion_de_Honorabilidad.pdf"
            />
            <PdfButton
                isExternal
                className="mb-2"
                text="Reglamento interno del Comité de Ética y del régimen disciplinario de EFPA España"
                link="/descargas/reglamento-interno-comite-etica-del-regimen-disciplinario-efpa-españa.pdf"
            />
          </div>
          <div className="EfpaAssociate-content">
            <TitleBlock
              title="Ventajas para jubilados/prejubilados y desempleados"
              icon="far fa-edit"
            />
            <TitleBlock
              title="Jubilados/prejubilados"
              className="RetireeBenefits-subtitle"
            />
            <p className="EthicalCode-text">
              Cuota anual reducida de 30€ para cualquier certificado, teniendo en cuenta que:
            </p>
            <List
              items={listJubilados}
              className="RetireeBenefits-list margin-b"
            />
            <TitleBlock
              title="Desempleados (EFP, EFA, EIP, EIA, ESG Advisor, LCCI)"
              className="RetireeBenefits-subtitle"
            />
            <List items={listWithChildren} className="RetireeBenefits-list" />
          </div>
          <div className="EfpaAssociate-content">
            <TitleBlock title="Recertificación" icon="far fa-edit" />

            <TitleBlock
              title="¿En qué consiste la recertificación?"
              className="RetireeBenefits-subtitle"
            />
            <p className="EthicalCode-text margin-b-36">
              A su vez, los Miembros empleados pueden estar sujetos a normas y
              requisitos específicos como consecuencia del contrato laboral.
            </p>
            <TitleBlock
              title="¿Cómo hacerlo?"
              className="RetireeBenefits-subtitle"
            />
            <p className="EthicalCode-text">
              En función de la certificación profesional que se dispone se
              deberá realizar y justificar un mínimo de horas de formación
              continua (HFR*) en temas vinculados y complementarios a los
              contenidos del certificado obtenido y ratificar el código ético.
            </p>
            <img
              src="/imgs/piramide-recertificacion 2.jpg"
              className="ActivityTab-img mx-auto d-block my-2"
              alt="Piramide recertificacion"
            />
            <p className="EthicalCode-text">
              <strong>
                A partir de 2018 el número de horas para la certificación EIA
                serán 20 horas cada año, para el EIP y EFA serán 30 horas cada
                año y para el EFP, 35 horas anuales.
              </strong>
            </p>
            <p className="EthicalCode-text margin-b-36">
              <i>
                (*) HFR (Horas de formación para la recertificación): Número de
                horas otorgadas por el Comité de Acreditación y Certificación de
                EFPA España según el programa y duración de la formación
                aportada. Se debe tener en cuenta que el número de horas de una
                actividad formativa no tiene porqué ser equivalente a las horas
                de formación otorgadas para la recertificación.
              </i>
            </p>
            <TitleBlock
              title="¿Cómo puedo demostrar horas de formación?"
              className="RetireeBenefits-subtitle"
            />
            <p className="EthicalCode-text">
              La formación continua puede incluir seminarios, formación interna
              de su entidad, asistencia a congresos, actividades formativas de
              EFPA presenciales y on-line (Aula Virtual EFPA), webinars,
              artículos de opinión, impartición de ponencias… (todos los temas
              han de estar relacionados con el asesoramiento y la planificación
              financiera).
            </p>
            <p className="EthicalCode-text margin-b-36">
              <strong>
                *El periodo aproximado para los trámites de gestión de la
                formación aportada para la recertificación es de entre 15 y 30
                días.{" "}
              </strong>
            </p>
            <TitleBlock
                title="Criterios para la recertificación de las certificaciones MIFID:"
                className="RetireeBenefits-subtitle"
            />
            <p className="EthicalCode-text margin-b-36">
              En el siguiente documento se detallan los criterios por parte de EFPA España para la recertificación
              de las certificaciones MIFID (EIA, EIP, EFA y EFP), en el que se especifica el número de horas
              máximas otorgadas por temática.{" "}<a
                href="https://www.efpa.es/descargas/documentos/criterios-recertificacion-2023_EFPA.pdf"
                className="Gallery-link"
            >
              <strong>Ver documento</strong>.
            </a>
            </p>
            <TitleBlock title="Importante:" className="mb-0" noBorder />
            <p className="EthicalCode-text margin-b-36">
              Para cualquier duda sobre la idoneidad y validez de un curso o
              seminario para ser reconocido para la recertificación; puede
              enviarse el programa del curso o actividad formativa a EFPA
              España, para que el Comité de Acreditación y Certificación
              confirme o deniegue su validez.
            </p>
            <TitleBlock
              title="¿Cómo enviar la formación continua realizada?:"
              className="RetireeBenefits-subtitle"
            />
            <ul className="EthicalCode-text List">
              <li className="List-item">
                En el apartado:{" "}
                <a
                  href="https://intranet.efpa.es/publicar-formacion-externa"
                  className="Gallery-link"
                >
                  <strong>"Mi formación"</strong>
                </a>{" "}
                y verás al día el estado de la tramitación de tus formaciones en
                el{" "}
                <a
                  href="https://intranet.efpa.es/seguimiento-envios-formacion"
                  className="Gallery-link"
                >
                  <strong>"Seguimiento de envíos"</strong>
                </a>
                .
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
